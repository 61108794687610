<template>
  <div v-if="quote" class="post-quote">
    <div>
      <b>{{$t('page.topic.lb_reply')}}&nbsp;
        <span v-if="inSoga">
          {{quote.floor}}<sup>#</sup>
        </span>
        <a v-else @click="$emit('on-jump', quote.floor)">
          {{quote.floor}}<sup>#</sup>
        </a>
      </b>
    </div>
    <div v-if="quote.content!==''" class="post-quote-content">
      <blockquote v-if="quote.content" v-html="quote.content"></blockquote>
      <blockquote v-else-if="quote.content==null">{{$t('page.topic.v_post_deleted')}}</blockquote>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PostQuote',
    props: {
      quote: {
        floor: Number,
        content: String
      },
      tid: Number,
      page_size: Number
    },
    data () {
      return {
        inSoga: this.$store.state.inSoga
      }
    }
  }
</script>

<style scoped>
  .post-quote {
    margin:3px;
  }
  .post-quote-content {
    padding: 2px;
    border: gray dashed 1px;
    color: gray;
    font-size: smaller;
    margin-bottom: 5px;
    max-width: 100%;
    max-height: 100px;
    overflow: hidden;
  }
</style>
