<template>
    <div class="i-quill" :class="classes">
        <div ref="editor" :style="styles"></div>
      <Modal v-model="image_edit_show" :width="image_edit.width+20" style="max-width: 80%;">
        <p slot="header" style="color:#f60;text-align:center;">
          <Icon type="ios-crop"></Icon>添加图片
        </p>
        <div :style="'width:'+(image_edit.width+6)+'px;'" style="max-height: 70vh;text-align:center;align-content: center;">
          <VueCropper style="margin-bottom: 5px;text-align:center;"
                      :style="'height:'+image_edit.height+'px;width:'+image_edit.width+'px;'"
                      ref="cropper"
                      :img="image_edit.img"
                      :outputSize="image_edit.size"
                      :outputType="image_edit.outputType"
                      :info="image_edit.info"
                      :full="image_edit.full"
                      :canMove="image_edit.canMove"
                      :canMoveBox="image_edit.canMoveBox"
                      :centerBox="image_edit.centerBox"
                      :original="image_edit.original"
                      :autoCrop="image_edit.autoCrop"
                      :autoCropWidth="image_edit.autoCropWidth"
                      :autoCropHeight="image_edit.autoCropHeight"
                      :fixedBox="image_edit.fixedBox"
                      @imgLoad="onImgLoaded"></VueCropper>
        </div>
        <div style="text-align:center">
          <i-switch @on-change="onCrop" v-model="image_edit.crop_mode">
            <Icon type="ios-crop" slot="open"></Icon>
            <Icon type="ios-move" slot="close"></Icon>
          </i-switch>
          <Button icon="ios-add" circle size="small"
                  @click="changeScale(1)"></Button>
          <Button icon="ios-remove" circle size="small"
                  @click="changeScale(-1)"></Button>
          <Button icon="ios-return-left" circle size="small"
                  @click="rotateLeft"></Button>
          <Button icon="ios-return-right" circle size="small"
                  @click="rotateRight"></Button>
        </div>
        <p slot="footer" style="text-align:center">
          <Button type="primary" @click="saveImage">添加</Button>
        </p>
      </Modal>
    </div>
</template>

<script>
  import Quill from 'quill'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import { VueCropper } from 'vue-cropper';
  // import ImageResize from 'quill-image-resize-module';

  export default {
    name: 'MultiEditor',
    components: {
      VueCropper
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      border: {
        type: Boolean,
        default: false
      },
      height: {
        type: Number
      },
      minHeight: {
        type: Number
      },
      tool_cfg: {
        type: Object,
        default () {
          return {
            share: {
              url_tip: 'Share URL:',
              name_tip: 'Share name:',
              icon_tip: 'Share icon URL:',
              icon_def: null
            }
          }
        }
      }
    },
    data () {
      const toolOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }, { background: [] }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        [{ direction: 'rtl' }],
        // [{ 'font': [] }],
        ['clean'],
        ['link', 'image', 'share']
      ];
      return {
        Quill: null,
        currValue: '',
        options: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            /* imageResize: {
              displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
              },
              modules: ['Resize', 'DisplaySize', 'Toolbar']
            }, */
            toolbar: {
              container: toolOptions,
              handlers: {
                image: (value) => {
                  const q = this.Quill || this.quill;
                  if (value) {
                    const host = q.host;
                    host.selectImage();
                  } else {
                    q.format('image', false);
                  }
                },
                share: (value) => {
                  const q = this.Quill || this.quill;
                  if (value) {
                    const host = q.host;
                    const shareUrl = prompt(host.tool_cfg.share.url_tip);
                    if (!shareUrl) {
                      q.format('share', false);
                      return;
                    }
                    const name = prompt(host.tool_cfg.share.name_tip);
                    let icon = prompt(host.tool_cfg.share.icon_tip);
                    if (!icon) {
                      icon = host.tool_cfg.share.icon_def;
                    }
                    const sel = q.getSelection(true);
                    q.insertEmbed(sel.index, 'Share-Cell', { img: icon, label: name, url: shareUrl });
                    // q.setSelection(sel.index + 1, 1);
                  } else {
                    q.format('share', false);
                  }
                }
              }
            }
          },
          placeholder: '',
          readOnly: false
        },

        image_edit_show: false,
        // https://github.com/xyxiao001/vue-cropper
        image_edit: {
          width: 320,
          height: 280,
          crop_mode: false,

          img: '', // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 0.8, // 剪切后的图片质量（0.1-1）
          full: false, // 输出原图比例截图 props名full
          outputType: 'png', // 裁剪生成额图片的格式
          canMove: true, // 能否拖动图片
          original: false, // 上传图片是否显示原始宽高
          canMoveBox: true, // 能否拖动截图框
          centerBox: true, // 截图框是否被限制在图片里面
          autoCrop: false, // 是否默认生成截图框
          fixedBox: false // 截图框固定大小,
        },
        fileObjs: {
          selector: null,
          reader: null
        }
      }
    },
    computed: {
      classes () {
        return [
          {
            'i-quill-no-border': !this.border
          }
        ]
      },
      styles () {
        const style = {}
        if (this.minHeight) {
          style.minHeight = `${this.minHeight}px`
        }
        if (this.height) {
          style.height = `${this.height}px`
        }
        return style
      }
    },
    watch: {
      value: {
        handler (val) {
          if (val !== this.currValue) {
            this.currValue = val
            if (this.Quill) {
              this.Quill.pasteHTML(this.value)
            }
          }
        },
        immediate: true
      }
    },
    methods: {
      init () {
        const editor = this.$refs.editor
        // 初始化编辑器
        this.Quill = new Quill(editor, this.options)
        this.Quill.host = this;
        // Quill.register('modules/imageResize', ImageResize);
        // 默认值
        this.Quill.pasteHTML(this.currValue)
        // 绑定事件
        this.Quill.on('text-change', (delta, oldDelta, source) => {
          const html = this.$refs.editor.children[0].innerHTML
          const text = this.Quill.getText()
          const quill = this.Quill
          // 更新内部的值
          this.currValue = html
          // 发出事件 v-model
          this.$emit('input', html)
          // 发出事件
          this.$emit('on-change', { html, text, quill })
        })
        // 将一些 quill 自带的事件传递出去
        this.Quill.on('text-change', (delta, oldDelta, source) => {
          this.$emit('on-text-change', delta, oldDelta, source)
        })
        this.Quill.on('selection-change', (range, oldRange, source) => {
          this.$emit('on-selection-change', range, oldRange, source)
        })
        this.Quill.on('editor-change', (eventName, ...args) => {
          this.$emit('on-editor-change', eventName, ...args)
        })
        // 引入源码中的BlockEmbed
        const BlockEmbed = Quill.import('blots/block/embed');
        // 定义新的blot类型
        class ShareCell extends BlockEmbed {
          static create (value) {
            const node = super.create();
            node.dataset.url = value.url;
            node.dataset.label = value.label;
            node.dataset.img = value.img;

            node.setAttribute('style', 'margin:1px;background-color:#dddddd;display:inline-block;width:100px;text-align:center;');

            const a = document.createElement('a');
            a.className = 'share-cell-link';
            a.setAttribute('href', value.url);
            a.setAttribute('target', '_blank');
            if (value.img) {
              const icon = document.createElement('img');
              icon.setAttribute('src', value.img);
              icon.setAttribute('style', 'width:100%;height:auto;');
              icon.className = 'share-cell-image';
              a.appendChild(icon);
            }
            if (value.label) {
              const label = document.createElement('span');
              label.innerText = value.label;
              // label.setAttribute('style', 'display:inline-block;width:100%;text-align:center;');
              label.className = 'share-cell-label';
              a.appendChild(label);
            }
            node.appendChild(a);

            node.setAttribute('contenteditable', 'false');
            return node;
          }

          // 返回节点自身的value值 用于撤销操作
          static value (node) {
            return node.dataset;
          }
        }
        // blotName
        ShareCell.blotName = 'Share-Cell';
        // class名将用于匹配blot名称
        ShareCell.className = 'share-cell';
        // 标签类型自定义
        ShareCell.tagName = 'div';
        Quill.register(ShareCell, true);

        const shareBtn = document.querySelector('.ql-share');
        shareBtn.classList.add('ivu-icon');
        shareBtn.classList.add('ivu-icon-ios-share');
      },
      selectImage () {
        this.image_edit.img = null;
        const file = document.createElement('input');
        file.setAttribute('type', 'file');
        file.setAttribute('accept',
                          'image/png, image/gif, image/jpg, image/jpeg, image/bmp');
        const onchange = (e) => {
          this.fileObjs.selector = null;
          // console.error(file.files[0]);
          // const target = e.target || e.srcElement;
          const reader = new FileReader();
          reader.onload = (data) => {
            this.fileObjs.reader = null;
            const res = data.target || data.srcElement;
            this.image_edit.img = res.result;
            this.image_edit_show = true;
          };
          this.fileObjs.reader = reader;
          reader.readAsDataURL(file.files[0]);
        };
        if (file.addEventListener) {
          file.addEventListener('change', onchange, false);
        } else {
          file.onchange = onchange;
        }
        // 使之不为临时变量
        this.fileObjs.selector = file;
        file.click();
      },
      saveImage () {
        this.$refs.cropper.getCropData((data) => {
          const q = this.Quill || this.quill;
          const sel = q.getSelection(true);
          q.insertEmbed(sel.index, 'image', data);
          this.image_edit.img = null;
          this.image_edit_show = false;
        });
      },
      // 放大/缩小
      changeScale (num) {
        num = num || 1;
        this.$refs.cropper.changeScale(num);
      },
      // 左旋转
      rotateLeft () {
        this.$refs.cropper.rotateLeft();
      },
      // 右旋转
      rotateRight () {
        this.$refs.cropper.rotateRight();
      },
      onImgLoaded (success) {
        if (!success) {
          this.image_edit_show = false;
        }
        // const axis = this.$refs.cropper.getImgAxis();
        // this.image_edit.width = Math.ceil(this.image_edit.height * (axis.x2 - axis.x1) / (axis.y2 - axis.y1));
      },
      onCrop (flag) {
        const cropper = this.$refs.cropper;
        if (flag) {
          cropper.startCrop();
        } else {
          cropper.stopCrop();
          cropper.clearCrop();
        }
      }
    },
    mounted () {
      this.init()
    },
    beforeDestroy () {
      // 在组件销毁后销毁实例
      this.Quill = null
    }
  }
</script>
<style lang="less">
    .i-quill-no-border{
        .ql-toolbar.ql-snow{
            border: none;
            border-bottom: 1px solid #e8eaec;
        }
        .ql-container.ql-snow{
            border: none;
        }
    }
</style>
