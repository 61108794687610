<template>
  <div>
    <div v-if="tid===0" style="width: 100%; display: table;">
      <Select style="width: 8em; display: table-cell;" :value="topic_type">
        <Option v-for="(label, key) in topic_types" :key="key" :value="key" :label="label"></Option>
      </Select>
      <Input v-model="title" autofocus maxlength="100" style="display: table-cell;"></Input>
    </div>
    <PostQuote v-else-if="quoteData" :quote="quoteData" :tid="tid" :page_size="0"></PostQuote>
    <div v-if="quoteData && quoteData.full" style="text-align: right;"><a @click="quoteFull">引用全部</a></div>
    <MultiEditor :border="true" v-model="content" :tool_cfg="tool_cfg"
                 :minHeight="200" style="margin-top: 5px;margin-bottom: 5px;"></MultiEditor>
    <div style="text-align: center;">
      <Button @click="doPublish" type="primary">
        <span v-if="post">{{$t('page.publish.btn_modify')}}</span>
        <span v-else>{{$t('page.publish.btn_publish')}}</span>
      </Button>
    </div>
  </div>
</template>

<script>
  import { httpGet, httpPost } from '@/api/data';
  import MultiEditor from '@/components/quill';
  import PostQuote from './post_quote';

  export default {
    name: 'Publish',
    components: {
      PostQuote,
      MultiEditor
    },
    props: {
      post: Object,
      fid: Number,
      tid: Number,
      quote: {
        type: Object,
        default: null
        /*
        id: Number,
        floor: Number,
        content: String
        */
      }
    },
    data () {
      return {
        tool_cfg: {
          share: {
            url_tip: this.$t('page.publish.share_url_tip'),
            name_tip: this.$t('page.publish.share_name_tip'),
            icon_tip: this.$t('page.publish.share_icon_tip'),
            icon_def: require('@/assets/share.gif')
          }
        },
        topic_types: {},
        topic_type: '0',
        publishing: false,
        title: '',
        content: '',
        // 引用
        quoteData: null
      }
    },
    methods: {
      initData () {
        if (this.tid === 0) {
          httpGet('/v1/bbs/flag/options')
            .then(res => {
              this.topic_types = res;
              for (const k in res) {
                this.topic_type = k;
                break;
              }
            }).catch(() => {
            });
        }
      },
      quoteFull () {
        const full = this.quoteData.full;
        this.quoteData.full = undefined;
        this.quoteData.content = full;
      },
      cleanData () {
        this.title = '';
        this.content = '';
        this.quoteData = null;
      },
      doPublish () {
        if (this.publishing) {
          return;
        }
        if (this.tid === 0) {
          // 发布主题
          if (this.topic_type.length === 0) {
            this.$Modal.warning({
              title: this.$t('common.alert_title'),
              content: this.$t('page.publish.tip_topic_type')
            });
            return;
          }
          this.title = this.title.trim();
          if (this.title.length === 0) {
            this.$Modal.warning({
              title: this.$t('common.alert_title'),
              content: this.$t('page.publish.tip_topic_title')
            });
            return;
          }
        }
        this.content = this.content.trim();
        // 判断是否有内容
        if (/^(\s|&nbsp;|<(?!(img|video))[^>]+>)+$/i.test(this.content)) {
          this.content = '';
        }
        if (this.content.length === 0) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.publish.tip_post_content')
          });
          return;
        }
        const maxSize = 500 << 10; // 限制500k一帖
        if (this.content.length > maxSize) {
          let limit = '<=';
          if (maxSize > 1 << 20) {
            const v = maxSize / (1 << 20);
            limit += (Math.floor(v * 100) / 100) + 'MB';
          } else if (maxSize > 1 << 10) {
            const v = maxSize / (1 << 10);
            limit += (Math.floor(v * 100) / 100) + 'KB';
          } else {
            limit += maxSize + 'Bytes';
          }
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.publish.tip_post_toolarge') + limit
          });
          return;
        }
        const postData = {
          message: this.content
        };
        this.publishing = true;
        if (this.post) {
          // 编辑
          httpPost('v1/bbs/post/' + this.post.id, postData).then(res => {
            this.cleanData();
            this.$emit('on-success', res);
          }).catch(() => {
          }).finally(() => {
            this.publishing = false;
          });
          return;
        }
        if (this.tid === 0) {
          postData.fid = this.fid;
          postData.flag_id = parseInt(this.topic_type);
          postData.subject = this.title;

          httpPost('/v1/bbs/topic', postData).then(res => {
            this.cleanData();
            this.$emit('on-success', res);
          }).catch(() => {
          }).finally(() => {
            this.publishing = false;
          });
        } else {
          postData.tid = this.tid;
          if (this.quoteData) {
            postData.quote_pid = this.quoteData.id;
            postData.quote_content = JSON.stringify(this.quoteData);
          }

          httpPost('/v1/bbs/post', postData).then(res => {
            this.cleanData();
            this.$emit('on-success', res);
          }).catch(() => {
          }).finally(() => {
            this.publishing = false;
          });
        }
      }
    },
    watch: {
      post () {
        this.publishing = false;
        if (this.post) {
          if (this.post.id === this.tid) {
            this.title = this.post.subject;
          }
          this.content = this.post.message;
        } else {
          this.content = '';
        }
      },
      quote () {
        if (this.quote) {
          this.quoteData = { ...this.quote };
        } else {
          this.quoteData = null;
        }
      }
    },
    mounted () {
      this.initData();
    }
  }
</script>

<style scoped>
</style>
